<template>
  <div id="app">
    <div class="wrap-banner">
      <div class="main">
        <vs-row>
          <vs-col style="text-align: center" w="12">
            <h1 style="font-size: 50px">Wicht
              <box-icon name='gift' type='solid' @click="tollerMensch"></box-icon>
              ln
            </h1>
          </vs-col>
        </vs-row>
        <div class="cards">
          <template v-for="item in gruppe">
            <template v-for="stuff in item">
              <vs-card class="card" type="1">
                <template #title>
                  <h3>Gruppe: {{ stuff.name }}</h3>
                </template>
                <template #img>
                  <img
                      alt=""
                      src="@/assets/wichtel.jpeg">
                </template>
                <template #text>
                  Hier stehen die Teilnehmer
                </template>
                <template #interactions>
                  <vs-button floating @click="showWichtelFunction(stuff.id)">
                    <box-icon name='door-open' type='solid'></box-icon>
                    Gruppe anzeigen
                    <template #animate>
                      <box-icon name='door-open' type='solid'></box-icon>
                    </template>
                  </vs-button>
                  <!--<vs-button danger @click="deleteGruppe">
                    <box-icon name='trash' type='solid'></box-icon>
                    löschen
                    <template #animate>
                      <box-icon name='trash' type='solid'></box-icon>
                    </template>
                  </vs-button>-->
                </template>
              </vs-card>
            </template>
          </template>
        </div>
      </div>
      <vue-particles :clickEffect="false" :hoverEffect="false" :lineOpacity="0.9" :linesDistance="150"
                     :linesWidth="2"
                     :moveSpeed="2" :particleOpacity="1" :particleSize="5" :particlesNumber="20"
                     class="particle-js"
                     color="#000000" linesColor="#000000" shapeType="star">
      </vue-particles>
    </div>
    <AddDialog/>
    <div v-if="getShowDialog">
      <ShowWichtelComponent/>
    </div>
  </div>
</template>

<script>

import AddDialog from './AddDialog'
import ShowWichtelComponent from './ShowWichtelComponent'

export default {
  name: 'App',
  components: { ShowWichtelComponent, AddDialog },
  created () {
    this.$store.dispatch('getGruppe')
  },
  methods: {
    tollerMensch () {
      this.$vs.notification({
        icon: '<box-icon name=\'heart-circle\'></box-icon>',
        duration: '10000',
        progress: 'auto',
        border: '#FF82AB',
        position: 'top-center',
        title: 'Du bist ein toller Mensch',
        text: 'Mit Freunden zeit verbringen ist das beste Geschenk'
      })
    },
    getShowDialog () {
      return this.$store.state.showWichtelDialog
    },
    showWichtelFunction (wichtelId) {
      this.$store.commit('SAVE_WICHTELID', { wichtelId: wichtelId })
      let wichtelIdFromVuex = this.$store.state.wichtelId
      this.$store.dispatch('getWichtel', wichtelIdFromVuex).then(() => {
        this.$store.commit('SAVE_SHOW_WICHTEL_DIALOG')
        this.$vs.notification({
          duration: '6000',
          progress: 'auto',
          color: 'primary',
          position: 'bottom-center',
          title: '' + this.$store.state.wichtel.data.name,
        })
      })
    }
  },
  data: () => ({
    active: false,
    wichtelId: 0,
  }),
  computed: {
    gruppe () {
      let gruppe = this.$store.state.gruppe
      return gruppe.data
    }
  },
}
</script>

<style>
h1 {
  margin-left: auto;
  margin-right: auto;
}

body {
  margin: 0;
}

.wrap-banner {
  position: relative;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
}

.main {
  background-color: #ffffff;
  width: 80vw;
  margin-top: 10vh;
  margin-bottom: 15vh;
  max-width: 2000px;
  z-index: 10;
  padding: 10px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.particle-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card {
  margin: 20px;
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

</style>
