import Vue from 'vue'
import Vuex from 'vuex'

import Vuesax from 'vuesax'

import 'vuesax/dist/vuesax.css' //Vuesax styles
import axios from 'axios'

import 'boxicons'

import VueParticles from 'vue-particles'
import { createWichtel } from '../components/Helper'

Vue.use(Vuesax, {
  colors: {
    primary: '#e95a0c',
    success: 'rgb(0,95,43)',
    danger: 'rgb(204, 0, 0)',
    warning: 'rgb(255, 130, 0)',
    dark: 'rgb(36, 33, 69)'
  }
})

Vue.use(VueParticles)

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    gruppe: [],
    wichtel: [],
    wichtelId: 1,
    showWichtelDialog: false,
    loesungLink: ''
  },
  mutations: {
    SAVE_GRUPPE (state, data) {
      state.gruppe = data
    },
    SAVE_WICHTEL (state, data) {
      state.wichtel = data
    },
    SAVE_WICHTELID (state, data) {
      state.wichtelId = data.wichtelId
    },
    SAVE_SHOW_WICHTEL_DIALOG (state) {
      if (state.showWichtelDialog === false) {
        state.showWichtelDialog = true
      } else {
        state.showWichtelDialog = false
      }
    },
    SAVE_LOESUNG_LINK (state, data) {
      state.loesungLink = data
    }
  },
  actions: {
    async getGruppe (context) {
      let data = await axios.get(
        'https://api.santa.timeglass.de/santa/'
      )
      context.commit('SAVE_GRUPPE', data)
    },
    async getWichtel (context, id) {
      let data = await axios.get(
        'https://api.santa.timeglass.de/santa/' + id + '/'
      )
      context.commit('SAVE_WICHTEL', data)
    },
    async postWichtel (context, payload) {
      let wichtelData = []
      for (let wicht of payload.wichtel) {
        wichtelData.push(createWichtel(wicht))
      }
      let data = await axios.post('https://api.santa.timeglass.de/santa/', {
        'name': '' + payload.gruppe_name,
        // 'date': '' + payload.date,
        'wichtel': wichtelData
      })
    },
    async getLoesung (context, id) {
      let preData = await axios.get(
        'https://api.santa.timeglass.de/santa/' + id + '/loesung/', { responseType: 'blob' }
      ).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        // fileLink.setAttribute('download', 'download.zip')
        document.body.appendChild(fileLink)

        fileLink.click()
      })
      /*const url = window.URL.createObjectURL(new Blob([preData.data]))
      context.commit('SAVE_LOESUNG_LINK', preData)*/
    }
  },
  modules: {}
})

