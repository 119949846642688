<template>
  <div>
    <div class="addButton">
      <vs-button floating size="xl" @click="active=!active">Neue Wichtel-Gruppe erstellen
        <template #animate>
          <box-icon name='user-plus' type='solid'></box-icon>
        </template>
      </vs-button>
    </div>
    <vs-dialog v-model="active">
      <template #header>
        <h2 class="not-margin">
          Erstelle eine neue Gruppe
          <box-icon name='group'></box-icon>
        </h2>
      </template>

      <div class="con-form">
        <vs-input v-model="gruppe_name" border placeholder="Gruppen Name">
          <template #icon>
            <box-icon name='group'></box-icon>
          </template>
        </vs-input>
        <br>
        <vs-input v-model="date" border placeholder="Date" type="date">
          <template #icon>
            <box-icon name='calendar-event'></box-icon>
          </template>
        </vs-input>
        <br>
        <vs-table>
          <template #thead>
            <vs-tr>
              <vs-th>
                Vorname
              </vs-th>
              <vs-th>
                Nachname
              </vs-th>
              <vs-th>
                EMail
              </vs-th>

            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr
                v-for="(tr, i) in wichtel"
                :key="i"
                :data="tr"
            >
              <vs-td>
                {{ tr[0] }}
              </vs-td>
              <vs-td>
                {{ tr[1] }}
              </vs-td>
              <vs-td>
                {{ tr[2] }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <br>
        <vs-row justify="space-around">
          <vs-col w="1">
            <vs-button icon @click="add=!add">
              <box-icon name='plus-circle'></box-icon>
            </vs-button>
          </vs-col>
          <vs-col w="1">
            <vs-button icon @click="subWichtel">
              <box-icon name='minus-circle'></box-icon>
            </vs-button>
          </vs-col>
        </vs-row>
      </div>

      <template #footer>
        <div class="footer-dialog">
          <vs-button v-if="wichtel.length >= 3 && gruppe_name.length > 1 && date.length > 2" block
                     @click="sendPostRequest">
            Erstellen
          </vs-button>
          <vs-button v-if="wichtel.length < 3 || gruppe_name.length === 0 || date.length < 3" block disabled>
            Mindestens 3 Wichtel + Gruppenname und Datum
          </vs-button>
        </div>
      </template>
    </vs-dialog>


    <vs-dialog v-model="add">
      <template #header>
        <h2 class="not-margin">
          Füge einen Wichtel hinzu
          <box-icon name='group'></box-icon>
        </h2>
      </template>

      <div class="con-form">
        <div style="display: flex; flex-wrap: wrap; flex-direction: row">
          <vs-input v-model="vorname" border placeholder="Vorname">
            <template #icon>
              <box-icon name='plus'></box-icon>
            </template>
            <template #message-warn>
              Benötigt
            </template>
          </vs-input>
          <vs-input v-model="nachname" border placeholder="Nachname">
            <template #icon>
              <box-icon name='plus'></box-icon>
            </template>
            <template #message-warn>
              Benötigt
            </template>
          </vs-input>
          <vs-input v-model="email" border placeholder="Email">
            <template #icon>
              <box-icon name='at'></box-icon>
            </template>
            <template v-if="validEmail" #message-success>
              Email Valid
            </template>
            <template v-if="!validEmail || email === ''" #message-danger>
              Email Invalid
            </template>
          </vs-input>
        </div>
      </div>

      <template #footer>
        <div class="footer-dialog">
          <vs-button v-if="email ==='' || vorname ==='' || nachname === '' || !validEmail" disabled @click="addWichtel">
            Wichtel hinzufügen
          </vs-button>
          <vs-button v-else @click="addWichtel">
            Wichtel hinzufügen
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>
<script>
export default {
  name: 'AddDialog',
  props: {},
  methods: {
    addWichtel () {
      this.wichtel.push([this.vorname, this.nachname, this.email])
      this.vorname = ''
      this.nachname = ''
      this.email = ''
      this.add = false
    },
    subWichtel () {
      this.wichtel.splice(-1, 1)
    },
    sendPostRequest () {
      this.$store.dispatch('postWichtel',
          {
            gruppe_name: this.gruppe_name,
            date: this.date,
            wichtel: this.wichtel,
          }).then(() => {
        this.$store.dispatch('getGruppe')
      })
      this.active = false
    }
  },
  computed: {
    validEmail () {
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
    }
  },
  data: () => ({
    add: false,
    active: false,
    gruppe_name: '',
    vorname: '',
    nachname: '',
    email: '',
    wichtel: [],
    date: ''
  }),
}
</script>
<style>
h1 {
  margin-left: auto;
  margin-right: auto;
}

body {
  margin: 0;
}

.addButton {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 20;
}
</style>
