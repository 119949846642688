<template>
  <div class="loesung">
    <h1>ZIP-Download</h1>
  </div>
</template>

<script>
export default {
  name: 'LoesungComponent',
  methods: {
    getLoesungLink () {
      return this.$store.state.loesungLink
    }
  },
  computed: {
    /*loesungLink: {
      get () {
        return this.$store.state.loesungLink
      }
    }*/
  }
}
</script>

<style scoped>
.loesung {
  height: 200px;
  width: 150px;
}
</style>
