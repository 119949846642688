<template>
  <vs-dialog v-model="showWichtel">
    <template #header>
      <h2 class="not-margin">
        Gruppe: {{ getGroupName }}
        <box-icon name='group'></box-icon>
      </h2>
    </template>

    <div class="con-form">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>
              Vorname
            </vs-th>
            <vs-th>
              Nachname
            </vs-th>

          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              v-for="(tr, i) in getWichtel"
              :key="i"
              :data="tr"
          >
            <vs-td>
              {{ tr.name }}
            </vs-td>
            <vs-td>
              {{ tr.surname }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <template #footer>
      <div class="footer-dialog">
        <vs-button block @click="loesen">
          Lösen
        </vs-button>
      </div>
    </template>
  </vs-dialog>
</template>
<script>
import LoesungComponent from './components/LoesungComponent'

export default {
  name: 'ShowWichtelComponent',
  props: {},
  methods: {
    closeDialog () {
      this.$store.commit('SAVE_SHOW_WICHTEL_DIALOG')
    },
    loesen () {
      let id = this.$store.state.wichtelId
      this.$store.dispatch('getLoesung', id).then(() => {
        this.$vs.notification({
          duration: '5000',
          progress: 'auto',
          content: LoesungComponent
        })
      })
    }
  },
  updated () {

  },
  computed: {
    getGroupName: {
      get () {
        return this.$store.state.wichtel.data.name
      },
    },
    getWichtel: {
      get () {
        return this.$store.state.wichtel.data.wichtel
      }
    },
    showWichtel: {
      get () {
        return this.$store.state.showWichtelDialog
      },
      set () {
        this.$store.commit('SAVE_SHOW_WICHTEL_DIALOG')
      }
    }
  },
  data: () => ({})
}
</script>
<style>
h1 {
  margin-left: auto;
  margin-right: auto;
}

body {
  margin: 0;
}

</style>
